/* eslint-disable react/prop-types */
import React from 'react';

import Gallery from '@content/Gallery';
import SiteHead from '@mainComponents/SiteHead';

const GallerySite = pageContext => <Gallery pageContext={pageContext} />;

export const Head = ({location}) => <SiteHead location={location} />;

export default GallerySite;