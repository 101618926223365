import React from 'react';

import Container from '@components/Container';
import Wrapper from '@components/layout/Wrapper';
import ImageGallery from '@components/surfaces/ImageGallery';
import useGetImages from '@hooks/useGetImages';
import Layout from '@mainComponents/Layout';
import PropTypes from 'prop-types';

const Gallery = ({ pageContext: {uri} }) => {
	const {images} = useGetImages();

	return (
		<Layout
			headerType='page-without-image'
			showHeader
			path={uri}
			pageTitle='Gallery'
			pageTopline='all our Images'
			pageDescription='Browse through the complete stock of our images'
    
		>
			<Wrapper>
				<Container noMargins >
					<ImageGallery images={images} showFilter imagesToDisplay={64} />
				</Container>
			</Wrapper>

		</Layout>
	);
};

Gallery.propTypes = {
	pageContext: PropTypes.object.isRequired
};

export default Gallery;